import './Planclases.css';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import React, { useState } from 'react';
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button'
import {Carousel} from '3d-react-carousal';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table'




import bannerplanclases from "./imgrecursos2/bannerplanclases.jpg"

import iconyoga from "./imgrecursos2/iconyoga.jpg"
import iconzumba from "./imgrecursos2/iconzumba.jpg"
import iconcycling from "./imgrecursos2/iconcycling.jpg"
import iconpilates from "./imgrecursos2/iconpilates.jpg"
import iconbox from "./imgrecursos2/iconbox.jpg"
import icongym from "./imgrecursos2/icongym.jpg"
import iconreloj from "./imgrecursos2/iconreloj.jpg"


function App() {
    return (
      <div className="App">
        <div className='seccion1clas'>
            <div>
                < Image src={bannerplanclases} width="100%"/>
                <div className='box-text-img-clas'>
                    <div>
                        <p className='text-img-1-clas' style={{color: "white"}}>Plan</p>
                        <p className='text-img-2-clas' style={{color: "#C6FF3B"}}>clases</p>
                        
                    </div>
                    <div>
                        <p className='text-img-3-clas' style={{color: "white"}}>
                         Ven a las clases que quieras en el horario que quieras
                        </p>
                    </div>
                </div>
            </div>
        </div>


{/*--------------------------------------------------------------------------------- */}
        <div className='seccion2clas'>
            <div>
                
                <div className='title-icons-clas'>
                    <p>
                     ¿Qué obtienes?
                    </p>
                </div>
            </div>
            <div  className='icono-planclas'>
                <div>
                < Image src={iconyoga} width="50%"/>
                </div>
                <div className='box-text-clas'>
                    <p className='title-icons-clas'>
                     Yoga
                    </p>
                    <p className='text-icons'>
                    Es una práctica que conecta el cuerpo, la respiración y la mente.<br/>
                    Esta práctica utiliza posturas físicas, ejercicios de respiración y meditación para <br/>
                    mejorar la salud general.
                    </p>
                </div>
            </div>
            {/*<div className='icono-planclas'>
                <div>
                < Image src={iconzumba} width="50%"/>
                </div>
                <div className='box-text-clas'>
                    <p className='title-icons-clas'>
                     Zumba
                    </p>
                    <p className='text-icons'>
                    Es un movimiento o disciplina fitness de origen colombiano, enfocado por una <br/>
                    parte a mantener un cuerpo saludable y por otra a desarrollar, fortalecer y dar<br/>
                    flexibilidad al cuerpo mediante movimientos de baile combinados con una serie<br/>
                    de rutinas aeróbicas.
                    </p>
                </div>
            </div>*/}
            <div className='icono-planclas'>
                <div>
                    < Image src={iconcycling} width="50%"/>
                </div>
                <div className='box-text-clas'>
                    <p className='title-icons-clas'>
                     Cycling
                    </p>
                    <p className='text-icons'>
                    Se trata de un ejercicio físico colectivo, el cual se realiza sobre una bicicleta<br/>
                    estática al ritmo de la música, en la que se efectúa un trabajo cardiovascular de<br/>
                    alta intensidad con intervención muy elevada de los grandes grupos musculares <br/>
                    del tren inferior.
                    </p>
                </div>
            </div>
           {/*} <div  className='icono-planclas'>
                <div>
                < Image src={iconpilates} width="50%"/>
                </div>
                <div className='box-text-clas'>
                    <p className='title-icons-clas'>
                    Pilates
                    </p>
                    <p className='text-icons'>
                     El método Pilates forma parte del grupo de ejercicios anaeróbicos, es decir,<br/>
                     aquellas actividades físicas que se realizan de forma breve y que se basan,<br/>
                     fundamentalmente, en la fuerza. Su objetivo principal es reforzar la musculatura y<br/>
                     aumentar el control, fuerza y flexibilidad de nuestro cuerpo.
                    </p>
                </div>
            </div>*/}
            <div  className='icono-planclas'>
                <div>
                < Image src={icongym} width="50%"/>
                </div>
                <div className='box-text-clas'>
                    <p className='title-icons-clas'>
                     Gym
                    </p>
                    <p className='text-icons'>
                     Ponemos a tu disposición un espacio especialmente equipado para realizar tus <br/>
                     actividades físicas con la intensidad que desees, de la mano con el coach.
                    </p>
                </div>
            </div>
            <div  className='icono-planclas'>
                <div>
                < Image src={iconbox} width="50%"/>
                </div>
                <div className='box-text-clas'>
                    <p className='title-icons-clas'>
                      Power Box
                    </p>
                    <p className='text-icons'>
                    Libera estrés, tonifica tu cuerpo y realiza cardio, todo en esta intensa y divertida <br/>
                    clase de boxeo.
                    </p>
                </div>
            </div>
            <div className='icono-planclas-reloj'>
                <div className='box-reloj'>
                    <div className='icon-reloj'>
                    < Image src={iconreloj} width="30%"/>
                    </div>
                    <div className='title-reloj'> 
                        <p>
                         Horarios a elegir
                        </p>
                    </div>
                </div>
                <div className='tabla-horarios'>
                <Table  responsive="sm" className='tabla-horarios'>
                  <thead>
                    <tr className='linea-sup-dias'>
                      <th className='box-horas-clas'> </th>
                      <th className='th-box-gradient'>LUNES</th>
                      <th className='th-box-gradient'>MARTES</th>
                      <th className='th-box-gradient'>MIÉRCOLES</th>
                      <th className='th-box-gradient'>JUEVES</th>
                      <th className='th-box-gradient'>VIERNES</th>
                    
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='box-horas-clas'><div>06:00 AM</div></td>
                      <td><div></div></td>
                      <td><div></div></td>
                      <td><div></div></td>
                      <td><div></div></td>
                      <td><div></div></td>
                 
                    </tr>
                    <tr>
                      <td className='box-horas-clas'>07:00 AM</td>
                      <td><div className='td-border-green'>indoor cycling</div></td>
                      <td><div></div></td>
                      <td><div className='td-border-green'>indoor cycling</div></td>
                      <td><div></div></td>
                      <td><div className='td-border-green'>indoor cycling</div></td>
                     
                    </tr>
                    <tr>
                      <td className='box-horas-clas'>08:00 AM</td>
                      <td><div></div></td>
                      <td><div></div></td>
                      <td><div></div></td>
                      <td><div></div></td>
                      <td><div></div></td>
                      
                    </tr>
                    <tr>
                      <td className='box-horas-clas'>09:00 AM</td>
                      <td><div></div></td>
                      <td><div></div></td>
                      <td><div></div></td>
                      <td><div></div></td>
                      <td><div></div></td>

                    </tr>
                    <tr>
                      <td className='box-horas-clas'>10:00 AM</td>
                      <td><div className='td-border-green'>dance party</div></td>
                      <td><div className='td-border-green'>Yoga</div></td>
                      <td><div className='td-border-green'>dance party</div></td>
                      <td><div className='td-border-green'>Yoga</div></td>
                      <td><div className='td-border-green'>dance party</div></td>
                      
                    </tr>
                    <tr>
                      <td className='box-horas-clas'>11:00 AM</td>
                      <td><div></div></td>
                      <td><div></div></td>
                      <td><div></div></td>
                      <td><div></div></td>
                      <td><div></div></td>
                      <td><div></div></td>
                    </tr>
                    <tr>
                      <td className='box-horas-clas'></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      
                    </tr>
                    <tr>
                      <td className='box-horas-clas'>05:00 PM</td>
                      <td><div className='td-border-green'>power box</div></td>
                      <td><div className='td-border-green'>fitness jumping</div></td>
                      <td><div className='td-border-green'>power box</div></td>
                      <td><div className='td-border-green'>fitness jumping</div></td>
                      <td><div className='td-border-green'>power box</div></td>
                     
                    </tr>
                    <tr>
                      <td className='box-horas-clas'>06:00 PM</td>
                      <td><div></div></td>
                      <td><div></div></td>
                      <td><div></div></td>
                      <td><div></div></td>
                      <td><div></div></td>
                     
                    </tr>
                    <tr>
                      <td className='box-horas-clas'>07:00 PM</td>
                      <td><div></div></td>
                      <td><div className='td-border-green'>indoor cycling</div></td>
                      <td><div></div></td>
                      <td><div className='td-border-green'>indoor cycling</div></td>
                      <td><div></div></td>
                      
                    </tr>
                    <tr>
                      <td className='box-horas-clas'>08:00 PM</td>
                      <td><div></div></td>
                      <td><div className='td-border-green'>dance party</div></td>
                      <td><div></div></td>
                      <td><div className='td-border-green'>dance party</div></td>
                      <td><div></div></td>
                      
                    </tr>
                  </tbody>
                </Table>
                </div>
            </div>
            <div className='boxes-clas'>

                <div className='box-precios-clas'>
                    <p className='text-precios-clas'>$250</p>
                    <p className='text-clas-clas'>1 clase</p>
                    <div>
                       <a href="https://wa.me/5215518271695?text=Quiero%20agendar%201%20clase%20" target="_blank"> <p className='box-text-plans-clas'>Agendar clase</p></a>
                    </div>
                </div>
                
                <div className='box-precios-clas'>
                    <p className='text-precios-clas'>$1,150</p>
                    <p className='text-clas-clas'>5 clases</p>
                    <div>
                       <a href="https://wa.me/5215518271695?text=Quiero%20agendar%205%20clases%20" target="_blank"> <p className='box-text-plans-clas'>Agendar clases</p></a>
                    </div>

                </div>
               
                <div className='box-precios-clas'>
                    <p className='text-precios-clas'>$2,000</p>
                    <p className='text-clas-clas'>10 clases</p>
                    <div>
                       <a href="https://wa.me/5215518271695?text=Quiero%20agendar%2010%20clases%20" target="_blank"> <p className='box-text-plans-clas'>Agendar clases</p></a>
                    </div>
                    
                </div>

                <div className='box-precios-clas'>
                    <p className='text-precios-clas'>$2,700</p>
                    <p className='text-clas-clas'>15 clases</p>
                    <div>
                       <a href="https://wa.me/5215518271695?text=Quiero%20agendar%2015%20clases%20" target="_blank"> <p className='box-text-plans-clas'>Agendar clases</p></a>
                    </div>
                    
                </div>

                <div className='box-precios-clas'>
                    <p className='text-precios-clas'>$3,1200</p>
                    <p className='text-clas-clas'>20 clases</p>
                    <div>
                       <a href="https://wa.me/5215518271695?text=Quiero%20agendar%2020%20clases%20" target="_blank"> <p className='box-text-plans-clas'>Agendar clases</p></a>
                    </div>
                </div>
                
            </div>
            <div className='vigencia-box'>
              *Vigencia de 1 mes
            </div>
        </div>
      </div>
    );
  }
  
  export default App;