import './Plantransformacion.css';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import React, { useState } from 'react';
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button'
import {Carousel} from '3d-react-carousal';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import bannerplantransformacion from "./imgrecursos2/bannerplantransformacion.jpg"

import iconbodycare from "./imgrecursos2/iconbodycare.jpg"
import iconpesa from "./imgrecursos2/iconpesa.jpg"
import iconmanzana from "./imgrecursos2/iconmanzana.jpg"
import iconreloj from "./imgrecursos2/iconreloj.jpg"



function Plantransformacion() {
    return (
      <div className="Plantransformacion">
        <div className='seccion1trans'>
            <div>
                < Image src={bannerplantransformacion} width="100%"/>
                <div className='box-text-img-trans'>
                    <div>
                        <p className='text-img-1-trans' style={{color: "white"}}>Plan de</p>
                        <p className='text-img-2-trans' style={{color: "#C6FF3B"}}>transformación</p>
                        
                    </div>
                    <div>
                        <p className='text-img-3-trans' style={{color: "white"}}>El plan más completo que te dará el cambio que tanto ansias.</p>
                    </div>
                </div>
            </div>
            

        </div>


{/*--------------------------------------------------------------------------------- */}
        <div className='seccion2trans'>
            <div>
                
                <div className='title-icons-trans'>
                    <p>
                     ¿Qué obtienes?
                    </p>
                </div>
            </div>
            <div  className='icono-plantrans'>
                <div>
                < Image src={iconpesa} width="50%"/>
                </div>
                <div className='box-text-trans'>
                    <p className='title-icons-trans'>
                    1 hora de entrenamiento al día y grupal
                    </p>
                    <p className='text-icons'>
                    Ejercicios funcionales, cardio, calistenia, circuitos, box, pesas y crossfit.
                    </p>
                </div>
            </div>
            <div className='icono-plantrans'>
                <div>
                < Image src={iconmanzana} width="50%"/>
                </div>
                <div className='box-text-trans'>
                    <p className='title-icons-trans'>
                     Nutrición
                    </p>
                    <p className='text-icons'>
                        Plan de alimentación profesional totalmente personalizado <br/>
                        para obtener resultados en corto tiempo.
                    </p>
                </div>
            </div>
            <div className='icono-plantrans'>
                <div>
                    < Image src={iconbodycare} width="50%"/>
                </div>
                <div className='box-text-trans'>
                    <p className='title-icons-trans'>
                     Body Care (1 sesión semanal)
                    </p>
                    <p className='text-icons'>
                        Recuperación muscular y/o moldeo corporal mediante presoterapia,<br/>
                        termolipólisis, electro estimulación, vacuum, radiofrecuencia, lipolaser,<br/>
                        HIFU (combate flacidez), drenaje linfático y cavitación.
                    </p>
                </div>
            </div>
            <div className='icono-plantrans-reloj'>
                <div className='box-reloj'>
                    <div className='icon-reloj'>
                    < Image src={iconreloj} width="30%"/>
                    </div>
                    <div className='title-reloj'> 
                        <p>
                        Horarios a elegir
                        </p>
                    </div>
                </div>
                <div className='text-reloj'>
                    <p>
                     Lunes a viernes: 06:00 am, 07:15 am, 08:30 am y 07:10 pm. Sábados: 07:00 am.
                    </p>
                </div>
            </div>
            <div className='boxes-trans'>
                
                 <div className='box-precios-trans'>
                    <p className='text-precios-trans'>$39,600</p>
                    <p className='text-plans-trans'>Anual</p>
                    <div>
                       <a href="https://wa.me/5215518271695?text=quiero%20informacion%20sobre%20el%20plan%20anual%20" target="_blank"> <p className='box-text-plans-trans'>info. sobre plan</p></a>
                    </div>
                 </div>
               
                
                <div className='box-precios-trans'>
                    <p className='text-precios-trans'>$43,800</p>
                    <p className='text-plans-trans'>Semestral</p>
                    <div>
                       <a href="https://wa.me/5215518271695?text=quiero%20informacion%20sobre%20el%20plan%20semestral%20" target="_blank"> <p className='box-text-plans-trans'>info. sobre plan</p></a>
                    </div>
                </div>
               
                <div className='box-precios-trans'>
                    <p className='text-precios-trans'>$8,000</p>
                    <p className='text-plans-trans'>Bimestral</p>
                    <div>
                       <a href="https://wa.me/5215518271695?text=quiero%20informacion%20sobre%20el%20plan%20bimestral%20" target="_blank"> <p className='box-text-plans-trans'>info. sobre plan</p></a>
                    </div>
                </div>
                
                <div className='box-precios-trans'>
                    <p className='text-precios-trans'>$4,500</p>
                    <p className='text-plans-trans'>Mensual</p>
                    <div>
                       <a href="https://wa.me/5215518271695?text=quiero%20informacion%20sobre%20el%20plan%20mensual%20" target="_blank"> <p className='box-text-plans-trans'>info. sobre plan</p></a>
                    </div>
                </div>
               
                <div className='box-precios-trans'>
                    <p className='text-precios-trans'>$1,300</p>
                    <p className='text-plans-trans'>Semanal</p>
                    <div>
                       <a href="https://wa.me/5215518271695?text=quiero%20informacion%20sobre%20el%20plan%20semanal%20" target="_blank"> <p className='box-text-plans-trans'>info. sobre plan</p></a>
                    </div>
                </div>
                {/*href="https://wa.me/5215518271695?text=I'm%20interested%20in%20your%20car%20for%20sale" target="_blank" */}
            </div>

        </div>
        
      </div>
    );
  }
  
  export default Plantransformacion;