import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.js';
import './Plantransformacion.js';
import './Planalimentacion';
import './Planbody';
import './Planteens';
import './Planclases';
import './Daypass';
import './Privacidad';
import './Legal'

import App from './App';
import reportWebVitals from './reportWebVitals';

import Plantransformacion from './Plantransformacion';
import Planalimentacion from './Planalimentacion';
import Planbody from './Planbody';
import Planteens from './Planteens';
import Planclases from './Planclases';
import Daypass from './Daypass';
import Privacidad from './Privacidad';
import Legal from './Legal';

var url_string = window.location.href
var url = new URL(url_string);

var t = url.searchParams.get("t");
var a = url.searchParams.get("a");
var b = url.searchParams.get("b");
var k = url.searchParams.get("k");
var c = url.searchParams.get("c");
var d = url.searchParams.get("d");
var p = url.searchParams.get("p");
var l = url.searchParams.get("l");

const root = ReactDOM.createRoot(document.getElementById('root'));


if( d  != undefined){
  root.render(
    <React.StrictMode> 
      <Daypass />
    </React.StrictMode>
  );
}else if ( c  != undefined){
  root.render(
    <React.StrictMode> 
      <Planclases/>
    </React.StrictMode>
  );
}else if ( k  != undefined){
  root.render(
    <React.StrictMode> 
      <Planteens />
    </React.StrictMode>
  );
}else if ( b  != undefined){
  root.render(
    <React.StrictMode> 
      <Planbody />
    </React.StrictMode>
  );
}else if ( a  != undefined){
  root.render(
    <React.StrictMode> 
      <Planalimentacion />
    </React.StrictMode>
  );
}else if ( t  != undefined){
  root.render(
    <React.StrictMode> 
      <Plantransformacion />
    </React.StrictMode>
  );
}else if ( p != undefined){
  root.render(
    <React.StrictMode> 
      <Privacidad />
    </React.StrictMode>
  );
}else if ( l != undefined){
  root.render(
    <React.StrictMode> 
      <Legal />
    </React.StrictMode>
  );
}else{
  root.render(
    <React.StrictMode> 
      <App />
    </React.StrictMode>
  );
}








// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
