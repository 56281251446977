import './Planbody.css';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import React, { useState } from 'react';
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button'
import {Carousel} from '3d-react-carousal';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';




import bannerplanbodycare from "./imgrecursos2/bannerplanbodycare.jpg"
import spa1 from "./imgrecursos1/spa1.jpg"
import spa2 from "./imgrecursos1/spa2.jpg"
import spa3 from "./imgrecursos1/spa3.jpg"
import spa4 from "./imgrecursos1/spa4.jpg"
import spa5 from "./imgrecursos1/spa5.jpg"
import spa6 from "./imgrecursos1/spa6.jpg"
import spa7 from "./imgrecursos1/spa7.jpg"
import spa8 from "./imgrecursos1/spa8.jpg"
import imgsbodycare from "./imgrecursos2/bodycareimgs.jpg"

import iconbodycare from "./imgrecursos2/iconbodycare.jpg"
import iconmasaje from "./imgrecursos2/iconmasaje.jpg"
import iconfacial from "./imgrecursos2/iconfacial.jpg"
import iconreloj from "./imgrecursos2/iconreloj.jpg"

function Planbody() {
    return (
      <div className="Planbody">
     <div className='seccion1body'>
            <div>
                < Image src={bannerplanbodycare } width="100%"/>
                <div className='box-text-img-body'>
                    <div>
                        <p className='text-img-1-body' style={{color: "white"}}>Plan</p>
                        <p className='text-img-2-body' style={{color: "#C6FF3B"}}>body care</p>
                        
                    </div>
                    <div>
                        <p className='text-img-3-body' style={{color: "white"}}>
                        Combina las mejores técnicas y tecnologías para moldear tu figura,
                        <br/> recuperar la musculatura y mejorar tu estado físico general.
                        </p>
                    </div>
                </div>
            </div>
        </div>
{/*--------------------------------------------------------------------------------- */}
        <div className='seccion2body'>
            <div>
                
                <div className='title-icons-body'>
                    <p>
                     ¿Qué obtienes?
                    </p>
                </div>
            </div>
            <div  className='icono-planbody'>
                <div>
                < Image src={iconbodycare} width="50%"/>
                </div>
                <div className='box-text-body'>
                    <p className='title-icons-body'>
                    Reduce y reafirma
                    </p>
                    <p className='text-icons'>
                    Termolipólisis, presoterapia, electro estimulación, vacuum, lipolaser,<br/>
                    HIFU (combate flacidez), drenaje linfático y cavitación.
                    </p>
                </div>
            </div>
            <div className='icono-planbody'>
                <div>
                < Image src={iconmasaje} width="50%"/>
                </div>
                <div className='box-text-body'>
                    <p className='title-icons-body'>
                    Relájate con masajes
                    </p>
                    <p className='text-icons'>
                    Maderoterapia, relajante ecléctico, vela, ventosas, descontracturante,<br/>
                    piedras calientes
                    </p>
                </div>
            </div>
            <div className='icono-planbody'>
                <div>
                    < Image src={iconfacial} width="50%"/>
                </div>
                <div className='box-text-body'>
                    <p className='title-icons-body'>
                    Faciales
                    </p>
                    <p className='text-icons'>
                    Limpieza profunda, Microdermoabrasión, Radiofrecuencia, Cupping, HIFU,<br/>
                    Lifting coreano
                    </p>
                </div>
            </div>
            <div className='icono-planbody-reloj'>
                <div className='box-reloj'>
                    <div className='icon-reloj'>
                    < Image src={iconreloj} width="30%"/>
                    </div>
                    <div className='title-reloj'> 
                        <p>
                        Horarios a elegir
                        </p>
                    </div>
                </div>
                <div className='text-reloj'>
                    <p>
                     Lunes a viernes: 06:00 am, 07:15 am, 08:30 am y 07:10 pm. Sábados: 07:00 am.
                    </p>
                </div>
            </div>
        </div>

        <div className='seccion3body'>
          <div></div>

          <div className='boxes-img-body'>

          < Image src={imgsbodycare} width="100%" height="100%"/>
            {/*<Row>
                  <div className='box-imgs-body'>
                    <div>< Image src={spa1} width="100%" height="100%"/></div>
                    <div>< Image src={spa2} width="100%" height="100%"/></div>
                    <div>< Image src={spa3} width="100%" height="100%"/></div>
                    <div>< Image src={spa4} width="100%" height="100%"/></div>
                  </div>
            </Row>     
            <Row>
                  <div className='box-imgs-body'>
                    <div>< Image src={spa5} width="100%" height="100%"/></div>
                    <div>< Image src={spa6} width="100%" height="100%"/></div>
                    <div>< Image src={spa7} width="100%" height="100%"/></div>
                    <div>< Image src={spa8} width="100%" height="100%"/></div>
                 </div>
            </Row>    */}
            </div>

          <div className='box-title-pack' >
            <p className='text-pack'>Paquetes - Reduce y reafirma</p>
          </div>
          <div className='boxes-body'>
               <div className='box-precios-body'>
                   <p className='text-precios-body'>$9,000</p>
                   <p className='text-plans-body'>16 sesiones</p>
                   <div>
                       <a href="https://wa.me/5215518271695?text=quiero%20informacion%20sobre%20el%20paquete%20de%2016%20sesiones%20de%20bodycare" target="_blank"> <p className='box-text-plans-body'>info. sobre plan</p></a>
                    </div>
               </div>
               
               <div className='box-precios-body'>
                   <p className='text-precios-body'>$5,500</p>
                   <p className='text-plans-body'>8 sesiones</p>
                   <div>
                       <a href="https://wa.me/5215518271695?text=quiero%20informacion%20sobre%20el%20paquete%20de%208%20sesiones%20de%20bodycare" target="_blank"> <p className='box-text-plans-body'>info. sobre plan</p></a>
                    </div>
               </div>
              
               <div className='box-precios-body'>
                   <p className='text-precios-body'>$3,000</p>
                   <p className='text-plans-body'>4 sesiones</p>
                   <div>
                       <a href="https://wa.me/5215518271695?text=quiero%20informacion%20sobre%20el%20paquete%20de%204%20sesiones%20de%20bodycare" target="_blank"> <p className='box-text-plans-body'>info. sobre plan</p></a>
                    </div>
               </div>
               
           </div>
           <div className='boxes-precios-body'>
            <div className='boxes-izq'>
              <p className='title-masajes-faciales'>Masajes</p>
              <div>
                <p className='title-precios-body'>Vela o ecléctico</p>
                <p className='des-precios-body'>Duración: 1 hora</p>
                <p className='des-precios-body'>Precio: $ 1,050 pesos</p>
              </div>
              <div>
                <p className='title-precios-body'>Piedras calientes y ventosas</p>
                <p className='des-precios-body'>Duración: 1:30 hora</p>
                <p className='des-precios-body'>Precio: $ 1,150 pesos</p>
              </div>
              <div>
                <p className='title-precios-body'>Maderoterapia - 3 zonas a elegir</p>
                <p className='des-precios-body'>Duración: 30 minutos</p>
                <p className='des-precios-body'>Precio: $ 650 pesos</p>
              </div>
              <div>
                <p className='title-precios-body'>Maderoterapia - cuerpo completo</p>
                <p className='des-precios-body'>Duración: 1 hora</p>
                <p className='des-precios-body'>Precio: $ 1,150 pesos</p>
              </div>
              <div>
                <p className='title-precios-body'>Reductor - 3 zonas a elegir</p>
                <p className='des-precios-body'>Duración: 30 minutos</p>
                <p className='des-precios-body'>Precio: $ 650 pesos</p>
              </div>
              <div>
                <p className='title-precios-body'>Reductor - cuerpo completo</p>
                <p className='des-precios-body'>Duración: 1 hora</p>
                <p className='des-precios-body'>Precio: $ 1,150 pesos</p>
              </div>
            </div>
            <div className='boxes-der'>
              <p className='title-masajes-faciales'>Faciales</p>
              <div>
                <p className='title-precios-body'>Limpieza</p>
                <p className='des-precios-body-red'>Duración: 1 hora</p>
                <p className='des-precios-body'>Precio: $ 1,050 pesos</p>
              </div>
              <div>
                <p className='title-precios-body'>Microdermoabrasión</p>
                <p  className='des-precios-body-red'>Duración: 1 hora</p>
                <p className='des-precios-body'>Precio: $ 1,050 pesos</p>
              </div>
              <div>
                <p className='title-precios-body'>Rejuvenecimiento (HIFU)</p>
                <p  className='des-precios-body-red'>Duración: 1 hora</p>
                <p className='des-precios-body'>Precio: $ 1,050 pesos</p>
              </div>
              <div>
                <p className='title-precios-body'>Lifting Coreano</p>
                <p  className='des-precios-body-red'>Duración: 1 hora</p>
                <p className='des-precios-body'>Precio: $ 1,150 pesos</p>
              </div>
              <div>
                <p className='title-precios-body'>Cupping</p>
                <p  className='des-precios-body-red'>Duración: 1 hora</p>
                <p className='des-precios-body'>Precio: $ 850 pesos</p>
              </div>
            </div>
           </div>
        </div>
      </div>
    );
  }
  
  export default Planbody;