import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Container } from 'react-bootstrap';


function Privacidad(){
    return(
        <div className="Privacidad">
            <Container>
                       <Row >
                <Col >
                    <br />
                    <br />
                    <br />
                    <Row className="justify-content-center subtitle_text"><h2>AVISO DE PRIVACIDAD</h2></Row>
                    <br />
                    <Row className="justify-content-center" >
                        <Col className="privacy-container ml-3 mr-3">
                            <Row className="mb-3 mt-3 mr-1 ml-1 form_fields_text">
                                <p className="text-justify">
                                    <br />
                                    Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares (en adelante la “Ley”) hacemos de su conocimiento que Proyecto Higo, S.A. de C.V. es la sociedad que opera el programa de entrenamiento físico multidisciplinario de fuerza, resistencia, flexibilidad, agilidad y velocidad, a través de ejercicios funcionales como pesas, cardio, circuitos y box denominado Legión 868 (en lo sucesivo Legión 868), la cual es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.
                                </p>
                                <p className="text-justify">
                                    A través del presente hacemos de su conocimiento que su información personal será utilizada para las finalidades que adelante se relacionan:
                                </p>
                                <ol className="text-left pr-3 ml-2">
                                    <li>Realizar su inscripción a Legión 868 e integrar su expediente de seguimiento.</li>
                                    <li>Dar cumplimiento a la relación jurídica establecida y asegurar la contratación de los servicios y/o
                                        productos ofrecidos por Legión 868.</li>
                                    <li>Permitirle su acceso a las instalaciones de Legión 868.</li>
                                    <li>Evaluación de sus registros médicos para control y gestión del tipo de dietas recomendadas para
                                        usted.</li>
                                    <li>Notificarle avisos, circulares, comunicados, estados de cuenta o cualquier otro tipo de documento relacionado con Legión 868.</li>
                                    <li>Integrar una base de datos de todos los miembros inscritos al programa Legión 868.</li>
                                    <li>Efectuar el cobro de los servicios y/o productos ofrecidos por Legión 868.</li>
                                    <li>Informarle sobre promociones y nuevos servicios ofrecidos por parte de Legión 868.
                                    </li>
                                </ol>
                                <p className="text-justify">Para las finalidades antes mencionadas, requerimos obtener datos personales de identificación, así como los documentos oficiales con los que compruebe dichos datos, tales como:</p>
                                <ol className="text-left pr-3 ml-2">
                                    <li>Nombre completo, esto es, apellido paterno, materno y nombre (s), sin abreviaturas.</li>
                                    <li>Domicilio actual.</li>
                                    <li>Fotografía y características faciales.</li>
                                    <li>Datos de contacto, tales como, número de teléfono fijo, móvil y correo electrónico.</li>
                                    <li>Copia de identificación oficial.</li>
                                    <li>Copia de comprobante de domicilio.</li>
                                    <li>Registros y antecedentes médicos.</li>
                                    <li>Datos financieros.</li>
                                </ol>
                                <p className="text-justify">
                                    Toda la información proporcionada y sus datos personales formarán parte de la(s) base(s) de datos propiedad de Legión 868. Dicha información deberá ser actualizada y será almacenada y protegida con medidas de seguridad administrativas, técnicas y físicas que eviten daños, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado de la misma.
                                </p>
                                <p className="text-justify">
                                    De conformidad con los artículos del 28 al 35 de la Ley, usted tiene derecho al Acceso, Rectificación y Cancelación de sus datos personales, a Oponerse al tratamiento de los mismos o a revocar el consentimiento que para dicho fin nos ha otorgado, salvo lo dispuesto por el artículo 26 de dicho ordenamiento.
                                </p>
                                <p className="text-justify">Para los fines señalados en el párrafo anterior, deberá enviar una solicitud por escrito a Proyecto Higo, S.A. de C.V., a la siguiente dirección Avenida Ruiz Cortines Manzana 1, Lote 20, PH, Colonia Fraccionamiento Lomas de Atizapán 2 sección, C.P. 52977, Atizapán de Zaragoza, Estado de México, Municipio de Atizapán de Zaragoza, Estado de México.</p>
                                <p className="text-justify">Una vez recibida su solicitud, nos pondremos en contacto con usted vía correo electrónico, en un plazo no mayor a 20 días naturales, a fin de comunicarle, en su caso, la procedencia de su solicitud.</p>
                                <p className="text-justify">Nos reservamos el derecho de efectuar en cualquier momento modificaciones al presente Aviso. Dichas modificaciones serán notificadas en el sitio web de Legión 868.</p>
                                <p className="text-justify">Al dar click al boton aceptar manifiesta haber leído el presente Aviso de Privacidad y otorgar su consentimiento expreso para el tratamiento de sus datos, conforme al mismo.</p>
                                <p></p>

                            </Row>
                           
                        </Col>
                    </Row>
                    <br />

                    <br />


                </Col>
            </Row>
            </Container>
        </div>
       
    );
}

export default Privacidad;