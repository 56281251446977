import './Planteens.css';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import React, { useState } from 'react';
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button'
import {Carousel} from '3d-react-carousal';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';




import bannerplanteens from "./imgrecursos2/bannerplanteens.jpg"

import iconpresoterapia from "./imgrecursos2/iconpresoterapia.jpg"
import iconpesa from "./imgrecursos2/iconpesa.jpg"
import iconmanzana from "./imgrecursos2/iconmanzana.jpg"
import iconreloj from "./imgrecursos2/iconreloj.jpg"


function Planteens() {
    return (
      <div className="Planteens">
       <div className='seccion1teens'>
            <div>
                < Image src={bannerplanteens} width="100%"/>
                <div className='box-text-img-teens'>
                    <div>
                        <p className='text-img-1-teens' style={{color: "white"}}>Plan</p>
                        <p className='text-img-2-teens' style={{color: "#C6FF3B"}}>teens</p>
                        
                    </div>
                    <div>
                        <p className='text-img-3-teens' style={{color: "white"}}>
                        Los buenos hábitos se construyen desde edad temprana, <br/>
                        por eso ¡este plan es ideal para tus hij@s!<br/>
                        Niñ@s y adolescentes de 6 a 17 años.
                        </p>
                    </div>
                </div>
            </div>
        </div>


{/*--------------------------------------------------------------------------------- */}
        <div className='seccion2teens'>
            <div>
                
                <div className='title-icons-teens'>
                    <p>
                     ¿Qué obtienes?
                    </p>
                </div>
            </div>
            <div  className='icono-planteens'>
                <div>
                < Image src={iconpesa} width="50%"/>
                </div>
                <div className='box-text-teens'>
                    <p className='title-icons-teens'>
                    1 hora de entrenamiento diario y en grupo
                    </p>
                    <p className='text-icons'>
                    Ejercicios funcionales, cardio, circuitos, box, crossfit y divertidos juegos.
                    </p>
                </div>
            </div>
            <div className='icono-planteens'>
                <div>
                < Image src={iconmanzana} width="50%"/>
                </div>
                <div className='box-text-teens'>
                    <p className='title-icons-teens'>
                     Nutrición
                    </p>
                    <p className='text-icons'>
                      Plan de alimentación profesional para una correcta alimentación.
                    </p>
                </div>
            </div>
            <div className='icono-planteens'>
                <div>
                    < Image src={iconpresoterapia} width="50%"/>
                </div>
                <div className='box-text-teens'>
                    <p className='title-icons-teens'>
                     Body Care
                    </p>
                    <p className='text-icons'>
                     6 sesiones de presoterapia que le ayudarán a tus hij@s en su recuperación muscular.
                    </p>
                </div>
            </div>
            <div className='icono-planteens-reloj'>
                <div className='box-reloj'>
                    <div className='icon-reloj'>
                    < Image src={iconreloj} width="30%"/>
                    </div>
                    <div className='title-reloj'> 
                        <p>
                        Horarios
                        </p>
                    </div>
                </div>
                <div className='text-reloj'>
                    <p>
                    Lunes a viernes: 06:00 pm <br/>
                    Sábados: 08:30 am
                    </p>
                </div>
            </div>
            <div className='boxes-teens'>
                
                <div className='box-precios-teens'>
                    <p className='text-precios-teens'>$5,000</p>
                    <p className='text-plans-teens'>Bimestral</p>
                    <div>
                       <a href="https://wa.me/5215518271695?text=quiero%20informacion%20sobre%20el%20plan%20bimestral%20teens" target="_blank"> <p className='box-text-plans-teens'>info. sobre plan</p></a>
                    </div>
                </div>
                
                <div className='box-precios-teens'>
                    <p className='text-precios-teens'>$0,000</p>
                    <p className='text-plans-teens'>Mensual</p>
                    <div>
                       <a href="https://wa.me/5215518271695?text=quiero%20informacion%20sobre%20el%20plan%20mensual%20teens" target="_blank"> <p className='box-text-plans-teens'>info. sobre plan</p></a>
                    </div>
                </div>
               
                <div className='box-precios-teens'>
                    <p className='text-precios-teens'>$0,000</p>
                    <p className='text-plans-teens'>Semanal</p>
                    <div>
                       <a href="https://wa.me/5215518271695?text=quiero%20informacion%20sobre%20el%20plan%20semanal%20teens" target="_blank"> <p className='box-text-plans-teens'>info. sobre plan</p></a>
                    </div>
                </div>
                
            </div>

        </div>
      </div>
    );
  }
  
  export default Planteens;