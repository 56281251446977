import './Planalimentacion.css';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import React, { useState } from 'react';
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button'
import {Carousel} from '3d-react-carousal';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';




import bannerplanalimentacion from "./imgrecursos2/bannerplanalimentacion.jpg"

import iconbodycare from "./imgrecursos2/iconbodycare.jpg"
import iconpesa from "./imgrecursos2/iconpesa.jpg"
import iconmanzana from "./imgrecursos2/iconmanzana.jpg"
import iconreloj from "./imgrecursos2/iconreloj.jpg"





function Planalimentacion() {
    return (
      <div className="Planalimentacion">
                <div className='seccion1ali'>
            <div>
                < Image src={bannerplanalimentacion} width="100%"/>
                <div className='box-text-img-ali'>
                    <div>
                        <p className='text-img-1-ali' style={{color: "white"}}>Plan de</p>
                        <p className='text-img-2-ali' style={{color: "#C6FF3B"}}>alimentación</p>
                        
                    </div>
                    <div>
                        <p className='text-img-3-ali' style={{color: "white"}}>Todo cambio comienza desde dentro.<br/>
                        Cambia tus hábitos internos para reflejar cambios externos.</p>
                    </div>
                </div>
            </div>
        </div>


{/*--------------------------------------------------------------------------------- */}
        <div className='seccion2ali'>
            <div>
                
                <div className='title-icons-ali'>
                    <p>
                     ¿Qué obtienes?
                    </p>
                </div>
            </div>
            
            <div className='icono-plantrans'>
                <div>
                < Image src={iconmanzana} width="50%"/>
                </div>
                <div className='box-text-ali'>
                    <p className='title-icons-ali'>
                    Nutrición, 1 consulta semanal
                    </p>
                    <p className='text-icons'>
                     Plan de alimentación profesional adecuado a tus gustos y necesidades<br/>
                     y sesiones de seguimiento para valorar y medir tus resultados.
                    </p>
                </div>
            </div>
            <div className='icono-planali'>
                <div>
                    < Image src={iconbodycare} width="50%"/>
                </div>
                <div className='box-text-ali'>
                    <p className='title-icons-ali'>
                    Body Care, 1 sesión semanal
                    </p>
                    <p className='text-icons'>
                      Moldeo corporal mediante presoterapia, termolipólisis, electro estimulación,<br/>
                      vacuum, radiofrecuencia, lipolaser, HIFU (combate flacidez), drenaje linfático<br/>
                       y cavitación. En tu cita de valoración definiremos el mejor programa para ti.
                    </p>
                </div>
            </div>
            <div className='icono-planali-reloj'>
                <div className='box-reloj'>
                    <div className='icon-reloj'>
                    < Image src={iconreloj} width="30%"/>
                    </div>
                    <div className='title-reloj'> 
                        <p>
                        Horarios (previa cita)
                        </p>
                    </div>
                </div>
                <div className='text-reloj'>
                    <p>
                    Lunes a viernes: por definir Sábados: por definir
                    </p>
                </div>
            </div>
            <div className='boxes-ali'>
                
                <div className='box-precios-ali'>
                    <p className='text-precios-ali'>$0,000</p>
                    <p className='text-plans-ali'>Bimestral</p>
                </div>
                
                <div className='box-precios-ali'>
                    <p className='text-precios-ali'>$0,000</p>
                    <p className='text-plans-ali'>Mensual</p>
                </div>
               
                <div className='box-precios-ali'>
                    <p className='text-precios-ali'>$0,000</p>
                    <p className='text-plans-ali'>Semanal</p>
                </div>
                
            </div>

        </div>
        
      </div>
    );
  }
  
  export default Planalimentacion;