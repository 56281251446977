import './Daypass.css';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import React, { useState } from 'react';
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button'
import {Carousel} from '3d-react-carousal';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';




import costales from "./imgrecursos1/costales.jpg"

import icondaypass from "./imgrecursos2/icondaypass.jpg"



function Daypass() {
    return (
      <div className="Daypass">
         <div className='seccion1day'>
            <div>
                < Image src={costales} width="100%"/>
                <div className='box-text-img-day'>
                    <div>
                        <p className='text-img-1-day' style={{color: "white"}}>Day</p>
                        <p className='text-img-2-day' style={{color: "#C6FF3B"}}>pass</p>
                        
                    </div>
                    <div>
                        <p className='text-img-3-day' style={{color: "white"}}>
                        ¿Quieres conocernos? <br/>
                        Adquiere el pase por un día
                        </p>
                    </div>
                </div>
            </div>
        </div>


{/*--------------------------------------------------------------------------------- */}
        <div className='seccion2day'>
            <div>
                
                <div className='title-icons-day'>
                    <p>
                     ¿Qué obtienes?
                    </p>
                </div>
            </div>
            <div  className='icono-planday'>
                <div>
                < Image src={icondaypass} width="50%"/>
                </div>
                <div className='box-text-day'>
                    <p className='title-icons-day'>
                    Acceso completo
                    </p>
                    <p className='text-icons'>
                    Entrenamiento grupal, clases y recorrido por las instalaciones
                    </p>
                </div>
            </div>
            <div className='boxes-day'>
                
                 <div className='box-precios-day'>
                    <p className='text-precios-day'>$0</p>
                    <p className='text-plans-day'>por una visita</p>
                    <div>
                       <a href="https://wa.me/5215518271695?text=quiero%20agendar%20mi%20clase%20de%20muestra" target="_blank"> <p className='box-text-plans-day'>¡agenda aqui!</p></a>
                    </div>
                 </div>
               
            </div>

        </div>
      </div>
    );
  }
  
  export default Daypass;