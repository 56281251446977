import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Container } from 'react-bootstrap';

function Legal(){
    return(
        <div className="Legal">
            <Container>
                <Row>
                    <Col>
                    <Row className="justify-content-center">CONSENTIMIENTO INFORMADO Y LIBERACIÓN DE RESPONSABILIDAD</Row>
                    <Row className="justify-content-center" >
                        <Col className="privacy-container ml-3 mr-3">
                            <Row className="mb-3 mt-3 mr-1 ml-1 form_fields_text">
                                <p>
                                            Manifiesto que deseo voluntariamente participar en el programa de ejercicio Legión 868 para tratar de mejorar y aumentar mi condición física.

                                            Entiendo que las actividades están diseñadas para aumentar gradualmente el trabajo músculo esquelético, metabólico y/o sistema cardiorespiratorio y así tratar de mejorar su función.

                                            Reconozco que la reacción de mi organismo al programa de ejercicios no puede ser predecida con exactitud y que existe un riesgo de ciertos cambios que pueden ocurrir durante o después de los ejercicios. Estos cambios pueden incluir anormalidades de la presión sanguínea o los latidos del corazón o alguna otra función de mi organismo.

                                            Entiendo que el propósito del programa de ejercicio es para desarrollar y mantener una condición cardiorespiratoria, composición corporal, flexibilidad, fuerza muscular y condición. El programa incluye, pero no está limitado a caminar, correr, ejercicio aeróbico y entrenamiento de fuerza o resistencia, circuitos, técnicas de boxeo. Todo el programa está diseñado en crear gradualmente un aumento de trabajo en el cuerpo, para aumentar la condición total.

                                            Manifiesto que todas mis preguntas con relación al programa de ejercicio han sido contestadas a mi satisfacción.

                                            Señalo bajo protesta de decir que soy apto para participar en el programa de ejercicio y en el evento de que se requiera un consentimiento médico antes de mi participación en el programa de ejercicio, estoy de acuerdo en consultar a mi médico y obtenerlo antes de comenzar el programa de ejercicio bajo mi exclusiva responsabilidad.

                                            De igual manera, me comprometo a cumplir todas y cada una de las normas de seguridad que me sean dadas durante mi participación en el programa.

                                            Para participar en el programa de ejercicio, estoy acuerdo en asumir el riesgo de los ejercicios que lo integran y que realizaré libremente y bajo mi total responsabilidad, por lo que en este acto libero de toda responsabilidad al respecto a Proyecto Higo, S.A. de C.V. (Legión 868), su personal, funcionarios, directivos y apoderados, incluyendo, pero no limitado a todo tipo de resposabilidad relacionada con lesiones, muerte, accidentes y/o cualquier tipo de daños, durante o de cualquier manera relacionado con el programa de ejercicio.

                                            Al firmar este documento de consentimiento, afirmo bajo protesta de decir verdad que leí este documento completamente y entiendo la naturaleza del programa de ejercicio.
                                </p>
                            </Row>
                        </Col>
                    </Row>
                    </Col>
                </Row>
            </Container>
        </div>
       
    );
}

export default Legal;