import './App.css';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import React, { useState } from 'react';
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button'
//import {Carousel} from '3d-react-carousal';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import '@brainhubeu/react-carousel/lib/style.css';
//import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import { arrowsPlugin } from '@brainhubeu/react-carousel';
//import Carousel from 'better-react-carousel'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


import * as TbIcons from "react-icons/tb";



import logos from "./imgrecursos2/logo.png"
import bannersup from "./imgrecursos2/banner.jpg"
import slogan from "./imgrecursos2/slogan.jpg"

import iconofbsup from "./imgrecursos2/iconofb.png"
import iconoigsup from "./imgrecursos2/iconoig.png"
import iconowhatssup from "./imgrecursos2/iconowhats.png"
import iconoubisup from "./imgrecursos2/iconoubi.png"
import iconomail from "./imgrecursos2/iconomail.png"
import coach1 from "./imgrecursos1/coach1.jpg"
import coach2 from "./imgrecursos1/coach2.jpg"
import coach3 from "./imgrecursos1/coach3.jpg"
import coach4 from "./imgrecursos1/coach4.jpg"
import coach1popup from "./imgrecursos1/coach1popup.jpg"
import coach2popup from "./imgrecursos1/coach2popup.jpg"
import coach3popup from "./imgrecursos1/coach3popup.jpg"
import coach4popup from "./imgrecursos1/coach4popup.jpg"
/*import coach1popup from "./imgrecursos1/coach1popup.jpg"
import coach2popup from "./imgrecursos1/coach2popup.jpg"
import coach3popup from "./imgrecursos1/coach3popup.jpg"*/
import coach1popupresponsivo from "./imgrecursos1/coach1popupresponsivo.jpg"
import coach2popupresponsivo from "./imgrecursos1/coach2popupresponsivo.jpg"
import coach3popupresponsivo from "./imgrecursos1/coach3popupresponsivo.jpg"
import coach4popupresponsivo from "./imgrecursos1/coach4popupresponsivo.jpg"



import coachalexis from "./imgrecursos1/alexis.jpg"
import coachalexispop from "./imgrecursos1/alexispupop.png"


import img1 from "./imgrecursos2/img-1.jpg"
import img2 from "./imgrecursos2/img-2.jpg"
import img3 from "./imgrecursos2/img-3.jpg"
import img4 from "./imgrecursos2/img-4.jpg"
import img5 from "./imgrecursos2/img-5.jpg"
import img6 from "./imgrecursos2/img-6.jpg"
import img7 from "./imgrecursos2/img-7.jpg"
import img8 from "./imgrecursos2/img-8.jpg"
import img9 from "./imgrecursos2/img-9.jpg"
import img10 from "./imgrecursos2/img-10.jpg"

import iconoclases from "./imgrecursos2/iconoclases.jpg"
import iconoentrenamiento from "./imgrecursos2/iconoentrenamiento.jpg"
import iconofaciales from "./imgrecursos2/iconofaciales.jpg"
import iconomasajes from "./imgrecursos2/iconomasajes.jpg"
import icononutricion from "./imgrecursos2/icononutricion.jpg"
import iconorecuperacion from "./imgrecursos2/iconorecuperacion.jpg"
import edificio from "./imgrecursos1/edificio3.jpg"
import mapa from "./imgrecursos1/mapa.png"


function App() {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const [lgShowcoach1, setLgShowcoach1] = useState(false);
  const [lgShowcoach2, setLgShowcoach2] = useState(false);
  const [lgShowcoach3, setLgShowcoach3] = useState(false);
  const [lgShowcoach4, setLgShowcoach4] = useState(false);
  const [lgShowcoach5, setLgShowcoach5] = useState(false);
  const [lgShowcoach6, setLgShowcoach6] = useState(false);
  const [lgShowcoach7, setLgShowcoach7] = useState(false);
  const [lgShowcoach8, setLgShowcoach8] = useState(false);

  const [show, setShow] = useState(false);

  
  {/*let slides = [
    <img  src={img1} alt="1" />,
    <img  src={img2} alt="2" />  ,
    <img  src={img3} alt="3" />  ,
    <img  src={img4} alt="4" />
     ];*/}

    
  return (
    <div className="App">

      <div>
        <div  className='seccion1'>
        {['md'].map((expand) => (
        <Navbar key={expand} expand={expand}>
          <Container fluid>
            <Navbar.Brand href="?=app">
              <Image 
                src={logos}
                width="40%"
              /></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas className='box-der-navs'
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  ¡Siguenos en nuestras redes!
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className='box-der-navs'>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link className='enlaces-sup' href="#servicios">Servicios</Nav.Link>
              <Nav.Link className='enlaces-sup' href="#coaches">Coaches</Nav.Link>
              <Nav.Link className='enlaces-sup' href="#galeria">Galería</Nav.Link>
              {/*<Nav.Link className='enlaces-sup' href="#planesl">Planes</Nav.Link>*/}
              <Container className='box-iconos-sup'>
                <div   className="iconsup1" target="_blank">
                  <a href="https://www.facebook.com/legion.868" target="_blank">
                   <Image src={iconofbsup} width="55%"/>
                  </a>
                </div>
            <div className="iconsup2">
              <a href="https://instagram.com/legion.868" target="_blank">
               <Image src={iconoigsup}  width="55%"/>
              </a>
            </div>
            <div className="iconsup3" >
              <a href="https://wa.me/5215518271695?text=I'm%20interested%20in%20your%20car%20for%20sale" target="_blank">
               <Image src={iconowhatssup}  width="55%"/>
              </a>
            </div>
              
            <div className="iconsup4">
              <a href="https://www.google.com/maps/place/Corporativo+Figtree/@19.552297,-99.2771797,17z/data=!3m1!4b1!4m6!3m5!1s0x85d21c9c5ae8d0ef:0xff978e3905a2173b!8m2!3d19.552292!4d-99.274991!16s%2Fg%2F11f_fq6qpw" target="_blank">
              <Image src={iconoubisup} className="iconsup4" width="55%"/>
              </a>
            </div>
              </Container>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
        </div>
      </div>
         {/*--------------------------------------------------------------------------------- */}
      <div className='seccion2'>
       < Image src={bannersup} width="100%" height="70%"/>
      </div>
      {/*----------------------------------------- */}
        <Container className='titulo-servicios'>
        <div>
              <p id="servicios" className='tit-servicios-1'>
                SERVICIOS
              </p>
              <p className='tit-servicios-2'>
                Explora todo lo que Legión 868 tiene para ti.
              </p>
            </div>
        </Container>
      <Container className='seccion3'>      
        <Col className='columnaizq'>
          <Row className='fila1-1'>
            <div className='filaentrenamiento'>
              <div>
                <h1 className='text-ser-h1'>ENTRENAMIENTO</h1>
                <h2 className='text-ser-h2'>Un programa de 8 semanas en equipo
                 para lograr tus objetivos</h2>
                <h3 className='text-ser-h3'>
                 Ejercicios funcionales - Cardio - Calistenia Circuitos - Box - Pesas - Crossfit
                </h3>
              </div>
              <div>
               < Image src={iconoentrenamiento} width="64%"/>
              </div>
            </div>
          </Row>
          <Row className='fila1-2'>
          <div className='filaentrenamiento'>
              <div>
                <h1 className='text-ser-h1'>CLASES</h1>
                <h2 className='text-ser-h2'>
                 ¡Actividades que te despiertan y activan!
                </h2>
                <h3 className='text-ser-h3'>
                 Zumba - Cycling - Pilates - Yoga - Gym
                </h3>
              </div>
              <div>
               < Image src={iconoclases} width="60%"/>
              </div>
            </div>
          </Row>
          <Row className='fila1-3'>
            <div className='filaentrenamiento'>
              <div>
                <h1 className='text-ser-h1'>NUTRICIÓN</h1>
                <h2 className='text-ser-h2'>
                Plan personalizado por nutrióloga deportiva
                </h2>
                <h3 className='text-ser-h3'>
                Una completa transformación requiere hábitos alimenticios saludables.
                ¡Lograrlo es más fácil de lo que crees!
                </h3>
              </div>
              <div>
               < Image src={icononutricion} width="100%"/>
              </div>
            </div>
          </Row>
        </Col>
        <Col className='columnader'>
          <Row className='fila2-1'>
            <div className='filaentrenamiento'>
             <div>
               < Image src={iconorecuperacion} width="100%"/>
              </div>
              <div>
                <h1 className='text-ser-h1'>RECUPERACIÓN MUSCULAR</h1>
                <h2 className='text-ser-h2'>
                  Aparatología para un completo reset de tu cuerpo
                </h2>
                <h3 className='text-ser-h3'>
                  Termolipólisis - Presoterapia - Vacuum Electroestimulación - Lipolaser - Drenaje linfático HIFU (combate flacidez) - Cavitación
                </h3>
              </div>
              
            </div>
          </Row>
          <Row className='fila2-2'>
            <div className='filaentrenamiento'>
              <div>
               < Image src={iconomasajes} width="90%"/>
              </div>
              <div>
                <h1 className='text-ser-h1'>TERAPIA DE MASAJES</h1>
                <h2 className='text-ser-h2'>
                  Relájate y rejuvenece
                </h2>
                <h3 className='text-ser-h3'>
                  Maderoterapia - Relajante ecléctico - Vela - Ventosas - Descontracturante - Piedras calientes
                </h3>
              </div>
              
            </div>
          </Row>
          <Row className='fila2-3'>
          <div className='filaentrenamiento'>
            <div>
               < Image src={iconofaciales} width="100%"/>
              </div>
              <div>
                <h1 className='text-ser-h1'>FACIALES</h1>
                <h2 className='text-ser-h2'>
                ¡Dale un respiro a tu rostro!
                </h2>
                <h3 className='text-ser-h3'>
                Limpieza profunda - Microdermoabrasión Radiofrecuencia - Cupping - HIFU - Lifting coreano
                </h3>
              </div>
              
            </div>
          </Row>
        </Col>
      </Container>

      {/*--------------------------------------------------------------------------------- */}

      <div className='seccion4'>
      <div className='text-coaches'><p id="coaches">CONOCE A TUS <strong className='colorgreencoaches'>COACHES</strong></p></div>
         <Container className='box-coaches'>
          <Col>
            <Row>
              <div  className='coaches'>
                <div className='img-coachprin' onClick={() => setLgShowcoach1(true)}>< Image src={coach1} width="25%"/></div>
                <Modal
                  size="lg"
                  show={lgShowcoach1}
                  onHide={() => setLgShowcoach1(false)}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Body className='modal-body'>
                  <Modal.Header className='modal-header' closeButton>
                  </Modal.Header>
                  < Image src={coach1popup} width="100%"/>
                  </Modal.Body>
                </Modal>
                </div> 
            </Row>
          </Col>
        </Container>
        </div>
               {/*--------------------------------------------------2 
                <div className='img-coachprin' onClick={() => setLgShowcoach2(true)}>< Image src={coach2} width="80%"/></div>
                <Modal
        size="lg"
        show={lgShowcoach2}
        onHide={() => setLgShowcoach2(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
         <Modal.Body className='modal-body'>
        <Modal.Header className='modal-header' closeButton>
        </Modal.Header>
         < Image src={coach2popup} width="100%"/>
        </Modal.Body>
      </Modal>*/}
               {/*  --------------------------------------------------3 
                <div className='img-coachprin' onClick={() => setLgShowcoach3(true)}>< Image src={coach3} width="80%"/></div>
                <Modal
        size="lg"
        show={lgShowcoach3}
        onHide={() => setLgShowcoach3(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
          <Modal.Body className='modal-body'>
        <Modal.Header className='modal-header' closeButton>
        </Modal.Header>
         < Image src={coach3popup} width="100%"/>
        </Modal.Body>
      </Modal>*/}
               {/*} --------------------------------------------------4 
                <div className='img-coachprin' onClick={() => setLgShowcoach4(true)}>< Image src={coach4} width="80%"/></div>
                <Modal
        size="lg"
        show={lgShowcoach4}
        onHide={() => setLgShowcoach4(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
         <Modal.Body className='modal-body'>
        <Modal.Header className='modal-header' closeButton>
        </Modal.Header>
         < Image src={coach4popup} width="100%"/>
        </Modal.Body>
      </Modal> 
              </div> 
            </Row>
          </Col>
        </Container>
        </div>*/}
     {/*-------------------------------------------------------- SECCION 4 RESPONSIVO*/}

     <div className='seccion4responsivo'>
      <div className='text-coaches'><p id="coaches">CONOCE A TUS <strong className='colorgreencoaches'>COACHES</strong></p></div>
         <Container className='box-coaches'>
          <Col>
            <Row>
              <div  className='coaches'>
                
                <div className='img-coachprin' onClick={() => setLgShowcoach5(true)}>< Image src={coach1} width="40%"/></div>
                <Modal
        size="lg"
        show={lgShowcoach5}
        onHide={() => setLgShowcoach5(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
       
        <Modal.Body className='modal-body'>
        <Modal.Header className='modal-header' closeButton>
        </Modal.Header>
         < Image src={coach1popupresponsivo} width="100%"/>
        </Modal.Body>
      </Modal>
      
               {/*{/*--------------------------------------------------2
                <div className='img-coachprin' onClick={() => setLgShowcoach6(true)}>< Image src={coach2} width="80%"/></div>
                <Modal
        size="lg"
        show={lgShowcoach6}
        onHide={() => setLgShowcoach6(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
         <Modal.Body className='modal-body'>
        <Modal.Header className='modal-header' closeButton>
        </Modal.Header>
         < Image src={coach2popupresponsivo} width="100%"/>
        </Modal.Body>
      </Modal> */}
               {/*  --------------------------------------------------3 
                <div className='img-coachprin' onClick={() => setLgShowcoach7(true)}>< Image src={coach3} width="80%"/></div>
                <Modal
        size="lg"
        show={lgShowcoach7}
        onHide={() => setLgShowcoach7(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
          <Modal.Body className='modal-body'>
        <Modal.Header className='modal-header' closeButton>
        </Modal.Header>
         < Image src={coach3popupresponsivo} width="100%"/>
        </Modal.Body>
      </Modal>*/}
               {/*} --------------------------------------------------4 
                <div className='img-coachprin' onClick={() => setLgShowcoach8(true)}>< Image src={coach4} width="80%"/></div>
                <Modal
        size="lg"
        show={lgShowcoach8}
        onHide={() => setLgShowcoach8(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
         <Modal.Body className='modal-body'>
        <Modal.Header className='modal-header' closeButton>
        </Modal.Header>
         < Image src={coach4popupresponsivo} width="100%"/>
        </Modal.Body>
      </Modal> */}
              </div> 
            </Row>
          </Col>
        </Container>
        </div>

       {/*--------------------------------------------------------------------------------- */}

      <div id="galeria" className='seccion5'>
        <Container className='carrousel'>
          <Row>
            <Col>
              <Carousel responsive={responsive}  infinite={true}>
                <div><Image src={img1} style={{ padding: "2%" }} width="100%"/></div>
                <div><Image src={img2} style={{ padding: "2%" }} width="100%"/></div>
                <div><Image src={img3} style={{ padding: "2%" }} width="100%"/></div>
                <div><Image src={img4} style={{ padding: "2%" }} width="100%"/></div>
                <div><Image src={img5} style={{ padding: "2%" }} width="100%"/></div>
                <div><Image src={img6} style={{ padding: "2%" }} width="100%"/></div>
                <div><Image src={img7} style={{ padding: "2%" }} width="100%"/></div>
                <div><Image src={img8} style={{ padding: "2%" }} width="100%"/></div>
                <div><Image src={img9} style={{ padding: "2%" }} width="100%"/></div>
                <div><Image src={img10} style={{ padding: "2%" }} width="100%"/></div>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
      {/*--------------------------------------------------------------------------------- */}
      <div className='seccion6'>
      <Row>
        <Col>
          < Image src={slogan} width="100%"/>
        </Col>
       </Row>
        
      </div>
      {/*--------------------------------------------------------------------------------- */}
      {/*<div className='seccion7'>
        <Container className='containersplans'>
          <div className='cont-plans'>
            <Col className='plans' id="planesl" >
             <div className='text-title-puntos'><p>Plan de <br/> <p className='colorgreen'>transformación</p></p></div>
             <div className='text-puntos'><p>Entrenamiento <br/> Nutrición <br/> Body Care</p></div>
             <a href="?t=plantransformacion" rel="noopener noreferrer" target="_blank"> <div className='box-vermas-trans'><Button type="submit">Ver más</Button>{' '}</div> </a>
            </Col>
            <Col className='plans' id="planalimentacio¡n">
              <div className='text-title-puntos'><p>Plan de <p className='colorgreen'>alimentación</p></p></div>
              <div className='text-puntos'><p>Nutrición <br/> Body Care</p></div>
              <Nav.Link href="?a=app" target="_blank"><div className='box-vermas-ali'><Button type="submit">Ver más</Button>{' '}</div></Nav.Link>
            </Col>
            <Col className='plans' id="planbodycare">
              <div className='text-title-puntos'><p>Plan <br/> <p className='colorgreen'>body care</p></p></div>
              <div className='text-puntos'><p>Aparatología <br/> Masajes <br/> Faciales</p></div>
              <Nav.Link href="?b=app" target="_blank"><div className='box-vermas-body'><Button type="submit">Ver más</Button>{' '}</div></Nav.Link>
              
            </Col>
          </div>
          <div className='cont-plans'>
            <Col className='plans' id="planteens">
              <div className='text-title-puntos'><p>Plan <br/> <strong className='colorgreen'>teens</strong> (6 a 17 años)</p></div>
              <div className='text-puntos'><p>Entrenamiento <br/> Nutrición <br/> Persoterapia</p></div>
              <Nav.Link href="?k=app" target="_blank"><div className='box-vermas-teens'><Button type="submit">Ver más</Button>{' '}</div></Nav.Link>
            </Col>
            <Col className='plans' id="planclases">
              <div className='text-title-puntos'><p>Plan <br/> <p className='colorgreen'>clases</p></p></div>
              <div className='container-doble-puntos'>
                <div className='text-puntos-clas-izq'><p>Yoga <br/> Cycling <br/> Zumba</p></div>
                <div className='text-puntos-clas-der'><p>Pilates <br/> Power box <br/> Gym</p></div>
              </div>
              <Nav.Link href="?c=app" target="_blank"><div className='box-vermas-clas'><Button type="submit">Ver más</Button>{' '}</div></Nav.Link>
            </Col>
            <Col className='plans' id="planpass">
              <div className='text-title-puntos'><p>Day <br/> <p className='colorgreen'>pass</p></p></div>
              <div className='text-puntos'><p>Un día muestra para que <br/> tomes el entrenamiento <br/> y las clases que quieras</p></div>
              <Nav.Link href="?d=app" target="_blank"> <div className='box-vermas-day'><Button type="submit">Ver más</Button>{' '}</div></Nav.Link>
            </Col>
          </div>
        </Container>
        {/*----------------------------------------------------------solo3cuadros*/}
       {/*} <Container className='containersplansmitad'>
         <div className='cont-plans-mitad'>
            <Col className='plans' id="planesl" >
             <div className='text-title-puntos'><p>Plan de <br/> <p className='colorgreen'>transformación</p></p></div>
             <div className='text-puntos'><p>Entrenamiento <br/> Nutrición <br/> Body Care</p></div>
             <a href="?t=plantransformacion" rel="noopener noreferrer" target="_blank"> <div className='box-vermas-trans'><Button type="submit">Ver más</Button>{' '}</div> </a>
            </Col>
            <Col className='plans' id="planclases">
              <div className='text-title-puntos'><p>Plan <br/> <p className='colorgreen'>clases</p></p></div>
              <div className='container-doble-puntos'>
                <div className='text-puntos-clas-izq'><p>Yoga <br/> Indoor cycling <br/>Fitness jumping</p></div>
                <div className='text-puntos-clas-der'><p>Dance party <br/> Power box <br/> Gym</p></div>
              </div>
              <Nav.Link href="?c=app" target="_blank"><div className='box-vermas-clas'><Button type="submit">Ver más</Button>{' '}</div></Nav.Link>
            </Col>
            <Col className='plans' id="planpass">
              <div className='text-title-puntos'><p>Day <br/> <p className='colorgreen'>pass</p></p></div>
              <div className='text-puntos'><p>Un día muestra para que <br/> tomes el entrenamiento <br/> y las clases que quieras</p></div>
              <Nav.Link href="?d=app" target="_blank"> <div className='box-vermas-day'><Button type="submit">Ver más</Button>{' '}</div></Nav.Link>
            </Col>
         </div>
        </Container>
      </div>*/}
      {/*--------------------------------------------------------------------------------- */}
      <div className='seccion8'>
        <Container className='contenedor-duda'>
          <div>
            <p className='text-dudas1'>¿Aún tienes dudas?</p>
          </div>
          <div>
            <p className='text-dudas2'>¡Contáctanos! Te ayudaremos a encontrar el plan que más te convenga.</p>
          </div>
        </Container>
      </div>
      {/*--------------------------------------------------------------------------------- */}
      <div className='seccion9'>
        <Container className='contenedor-ubicacion'>
          <Col className='box-map-ubicacion'>
            <div>
             <Image src={edificio} width="105%"/>
            </div>
          </Col>
          <Col className='box-map-responsive'>
            <div>
             <Image src={edificio} width="98%"/>
            </div>
          </Col>
          <Col className='box-ubicacion'>
          <div>
            <a href="https://www.google.com/maps/place/Legi%C3%B3n+868/@19.5522483,-99.2798082,17z/data=!3m1!4b1!4m6!3m5!1s0x85d21b824c4d9e17:0xcf899e6a2778ba5f!8m2!3d19.5522433!4d-99.2749373!16s%2Fg%2F11qh2l_wxz?entry=ttu" target="_blank"><Image src={mapa} width="100%"/></a>
            </div>
          </Col>
          <Col className='box-ubicacion'>
            <div className='box-ubicacion-text' >
              <p>Av. Ruiz Cortines Mz. 1 Lt. 20, Zona Esmeralda</p>
              <p>(A un costado de Galerías Atizapán)</p>
            </div>
          </Col>
        </Container>
      </div>

      {/*--------------------------------------------------------------------------------- */}
      <div className='seccion10'>
        <Container className='cont-redes'>
              <div className='box-redes'>
                <div className='box-redes-izq'>
                  <div className='box-icono'>
                    <div className='icono-inf'><Nav.Link href="https://instagram.com/legion.868" target="_blank"><Image src={iconoigsup} width="55%" className='img-icono-infe-1'/></Nav.Link></div>
                    <div className='text-icono-infe'><p>@legion.868</p></div>
                  </div>
                  <div className='box-icono'>
                  <div className='icono-inf'><Nav.Link href="https://wa.me/5215518271695" target="_blank"><Image src={iconowhatssup} width="55%" className='img-icono-infe-2'/></Nav.Link></div>
                    <div className='text-icono-infe'><p>55.1827.1695</p></div>
                  </div>
                </div>
                <div className='box-redes-der'>
                  <div className='box-icono'>
                  <div className='icono-inf'><Nav.Link href="https://www.facebook.com/legion.868" target="_blank"><Image src={iconofbsup} width="55%" className='img-icono-infe-3'/></Nav.Link></div>
                  <div className='text-icono-infe'><p>@legion.868</p></div>
                  </div>
                  <div className='box-icono'>
                    <div><Nav.Link className='icono-inf' href="mailto:contacto@legion868.com"><Image src={iconomail} width="28%" className='img-icono-infe-map4'/></Nav.Link></div>
                    <div className='text-icono-infe-map' id='text-icono-infe-map-4' target="_blank"><p>contacto@legion868.com</p></div>
                  </div>
                </div>
              </div>
              <div className='box-legales'> 
                <div className='box-aviso'><a href="?p=app" target="_blank"><p>Aviso de privacidad</p></a></div>
    
                <div className='box-aviso' id='box-legal'><a href="?l=app" target="_blank"><p>Legal</p></a></div>
              </div>
        </Container>

      </div>
    </div>
  );
}

export default App;
